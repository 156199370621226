angular.module('app').config(function ($stateProvider) {
    $stateProvider.state('index', {
        url: '/index',
        controller: 'IndexController',
        controllerAs: 'vm',
        templateUrl: 'tpl/index.html'
    });
}).controller('IndexController', function ($scope) {
    var vm = $scope.vm = this;

    $scope.$on('$state', function ($event, state) {
        state.page = 0;
        vm.currentState = state;
    });
});