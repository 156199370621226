angular.module('app').config(function ($stateProvider) {
    $stateProvider.state('index.tags', {
        url: '/tags',
        views: {
            'index': {
                controller: 'TagsController',
                controllerAs: 'vm',
                templateUrl: 'tpl/tags.html'
            }
        }
    });
}).controller('TagsController', function ($scope, $q, LoadBlog, Stat, UserBig) {
    var vm = $scope.vm = this;

    vm.count = 15;
    vm.page = 0;
    vm.ready = false;
    vm.field = 'cntname';
    vm.direction = 'DESC';
    vm.hasMorePages = false;

    vm.fields = [{
        name: 'ID',
        field: 'rownum'
    }, {
        name: 'Searched text',
        field: 'name'
    }, {
        name: 'Count of requests',
        field: 'cnt'
    }];

    vm.loadBlog = function () {
        if (vm.blogId)
            LoadBlog.get({id: vm.blogId}, function (resp) {
                if (resp && resp.ok == 'OK')
                    vm.blogId = null;
                else
                    console.log(resp);
            });
    };

    vm.previous = function (bolt) {
        if (vm.page == 0) return false;
        if (bolt) vm.page = 0;
        else vm.page--;
        vm.getAll();
    };

    vm.next = function () {
        if (vm.hasMorePages) {
            vm.page++;
            vm.getAll();
        }
    };

    vm.setOrderField = function (field, direction) {
        vm.field = field;
        vm.direction = direction;
        vm.getAll();
    };

    vm.getAll = function () {
        vm.ready = false;
        var deferred = $q.defer();
        var users2 = [];

        var params = {
            count: vm.count,
            from: vm.page * vm.count,
            username: vm.username,
            direction: vm.direction,
            field: vm.field,
            needs_count: true
        };

        Stat.query(params, function (huita) {
            var stat = huita.data;
            vm.hasMorePages = huita.hasMorePages;
            vm.stat = stat;
            stat.forEach(function (user) {
                if (users2.indexOf(user.username) == -1)
                    users2.push(user.username);
            });

            vm.ready = true;

            deferred.resolve(users2);
        });
    };
});
