angular.module('app').config(function ($stateProvider) {
    $stateProvider.state('index.users', {
        url: '/users?username&page&startsAt&endsAt&showBy&direction&field',
        params: {
            page: {
                value: '0',
                squash: true
            },
            showBy: {
                value: '15',
                squash: true
            },
            direction: {
                value: 'DESC',
                squash: true
            },
            field: {
                value: 'sumOfComments',
                squash: true
            },
            startsAt: {
                squash: true
            }
        },
        views: {
            'index': {
                controller: 'UserController',
                controllerAs: 'vm',
                templateUrl: 'tpl/users.html'
            }
        }
    });
}).controller('UserController', function ($scope, $q, $state, User, UserBig) {
    var vm = $scope.vm = this;

    vm.count = Math.max(1, +$state.params.showBy);
    vm.page = Math.max(0, +$state.params.page);
    vm.username = $state.params.username;
    if ($state.params.startsAt)
        vm.startsAt = new Date(+$state.params.startsAt * 1000);
    if ($state.params.endsAt)
        vm.endsAt = new Date(+$state.params.endsAt * 1000);
    vm.field = $state.params.field;
    vm.direction = $state.params.direction;
    vm.ready = false;

    vm.lastUser = vm.username;
    vm.lastC = vm.count;
    vm.firstTime = true;

    vm.fields = [{
        name: 'ID',
        field: 'rownum'
    }, {
        name: 'User',
        field: 'username',
        orderable: true
    }, {
        name: '#Comments',
        field: 'countOfComments',
        orderable: true
    }, {
        name: '#Blogs',
        field: 'countOfBlogs',
        orderable: true
    }, {
        name: 'Comments',
        field: 'sumOfComments',
        orderable: true
    }, {
        name: 'Blogs',
        field: 'sumOfBlogs',
        orderable: true
    }, {
        name: 'Contests',
        field: 'contestsTotal',
        orderable: true
    }, {
        name: 'Off',
        field: 'contestsOff',
        orderable: true
    }, {
        name: 'Unoff',
        field: 'contestsOff',
        orderable: true
    }];

    if (!vm.fields.filter(function (f) { return f.orderable }).find(function (f) { return f.field == vm.field } ))
        vm.field = 'sumOfComments';

    vm.previous = function (bolt) {
        if (vm.page == 0) return false;
        if (bolt) vm.page = 0;
        else vm.page--;
        vm.getAll();
    };

    vm.next = function () {
        if (vm.hasMorePages) {
            vm.page++;
            vm.getAll();
        }
    };

    vm.search = function (event) {
        if (!event || event.keyCode == 13) vm.getAll();
    };

    vm.setOrderField = function (field, direction) {
        vm.field = field;
        vm.direction = direction;
        vm.getAll();
    };

    vm.getAll = function () {
        vm.ready = false;
        var deferred = $q.defer();
        var users2 = [];

        var params = {
            count: vm.count,
            from: vm.page * vm.count,
            username: vm.username,
            direction: vm.direction,
            field: vm.field
        };
        if (vm.lastUser != vm.username || vm.lastC != vm.count) {
            vm.page = 0;
            params.from = 0;
        }
        vm.lastUser = vm.username;
        vm.lastC = vm.count;

        User.query(params, function (huita) {
            var users = huita.data;
            vm.users = users;
            vm.hasMorePages = huita.hasMorePages;

            users.forEach(function (user) {
                if (users2.indexOf(user.username) == -1)
                    users2.push(user.username);
            });

            vm.ready = true;
            params.page = vm.page;
            if (vm.startsAt)
                params.startsAt = Date.parse(vm.startsAt) / 1000;
            else
                params.startsAt = null;
            if (vm.endsAt)
                params.endsAt = Date.parse(vm.endsAt) / 1000;
            else
                params.endsAt = null;
            params.showBy = vm.count;
            $state.go('.', params, {notify: false});
            $scope.$emit('$state', params);
            vm.firstTime = false;

            deferred.resolve(users2);
        });

        deferred.promise.then(function (users2) {
            vm.users2 = [];
            if (users2.length) {
                var callback = function (users2) {
                    ok = true;
                    users2.result.forEach(function (user) {
                        if (user.rating >= 2400) {
                            user.color = 'red';
                        } else if (user.rating >= 2100) {
                            user.color = '#FF8C00';
                        } else if (user.rating >= 1900) {
                            user.color = '#AA00AA';
                        } else if (user.rating >= 1600) {
                            user.color = 'blue';
                        } else if (user.rating >= 1400) {
                            user.color = '#03A89E';
                        } else if (user.rating >= 1200) {
                            user.color = 'green';
                        } else {
                            user.color = 'gray';
                        }

                        if (!user.rating) user.color = '#000000';
                        user.lgm = user.rating >= 3000;
                        user.tourist = user.rating >= 4000;

                        vm.users2[user.handle] = user;
                    });

                    vm.users.forEach(function (user) {
                        if (vm.users2[user.username])
                            user.user = vm.users2[user.username];
                    });

                    vm.ready = true;
                };
                var newUsers = [];
                var ggg = function(users2, i) {
                    setTimeout(function() {
                        UserBig.query({users: users2.join(';')}).$promise.then(callback, function(x) {
                            if (users2.length == 1) return;
                            var l = users2.length + 1;
                            var users3 = users2.slice(0, l / 2);
                            var users4 = users2.slice(l / 2);
                            if (users3.length) newUsers.push(JSON.parse(JSON.stringify(users3)));
                            if (users4.length) newUsers.push(JSON.parse(JSON.stringify(users4)));
                        });
                    }, 1000 * i);
                };
                var get = function(users) {
                    newUsers = [];
                    for (var i = 0; i < users.length; ++i) ggg(users[i], i);
                    setTimeout(function() {
                        if (newUsers.length) get(newUsers);
                    }, users.length * 1000);
                };
                get([users2]);
            }
        });
    };
});
