angular.module('app').config(function ($stateProvider) {
    $stateProvider.state('index.blogs', {
        url: '/blogs?username&page&startsAt&endsAt&showBy&direction&field',
        params: {
            page: {
                value: '0',
                squash: true
            },
            showBy: {
                value: '15',
                squash: true
            },
            direction: {
                value: 'DESC',
                squash: true
            },
            field: {
                value: 'rating',
                squash: true
            }
        },
        views: {
            'index': {
                controller: 'BlogController',
                controllerAs: 'vm',
                templateUrl: 'tpl/blogs.html'
            }
        }
    });
}).controller('BlogController', function ($scope, $q, $state, Blog, UserBig) {
    var vm = $scope.vm = this;
    var trim = function(string) {
        string = (string || '').replace(/<p>/g, ' ').replace(/<.*?>/g, '').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/^\s+/, '').replace(/&quot;/g, '"');
        return string.length > 200 ? string.substr(0, 200) + '...' : string
    };

    vm.count = Math.max(1, +$state.params.showBy);
    vm.page = Math.max(0, +$state.params.page);
    vm.username = $state.params.username;
    if ($state.params.startsAt)
        vm.startsAt = new Date(+$state.params.startsAt * 1000);
    else {
        var x = localStorage.getItem('startsAt');
        var now = Date.now();
        var ok = x && now - x > 60 * 60 * 1000;
        if (ok)
            vm.startsAt = new Date(+x);
        if (!x || ok)
            localStorage.setItem('startsAt', now);
    }
    if ($state.params.endsAt)
        vm.endsAt = new Date(+$state.params.endsAt * 1000);
    vm.field = $state.params.field;
    vm.direction = $state.params.direction;

    vm.ready = false;
    vm.lastUser = vm.username;
    vm.lastS = vm.startsAt;
    vm.lastE = vm.endsAt;
    vm.lastC = vm.count;
    vm.firstTime = true;

    vm.fields = [{
        name: 'ID',
        field: 'rownum',
        minWidth: '50px'
    }, {
        name: 'User',
        field: 'username',
        orderable: true
    }, {
        name: 'Rating',
        field: 'rating',
        orderable: true,
        minWidth: '90px'
    }, {
        name: 'Created',
        field: 'createdAt',
        orderable: true,
        minWidth: '100px'
    }];
    if (!vm.fields.filter(function (f) { return f.orderable }).find(function (f) { return f.field == vm.field } ))
        vm.field = 'rating';

    vm.previous = function (bolt) {
        if (vm.page == 0) return false;
        if (bolt) vm.page = 0;
        else vm.page--;
        vm.getAll();
    };

    vm.next = function () {
        if (vm.hasMorePages) {
            vm.page++;
            vm.getAll();
        }
    };

    vm.lastMonth = function () {
        vm.startsAt = new Date();
        vm.startsAt.setMonth(vm.startsAt.getMonth() - 1);
        vm.endsAt = null;
        vm.search();
    };

    vm.lastDay = function () {
        vm.startsAt = new Date();
        vm.startsAt.setDate(vm.startsAt.getDate() - 1);
        vm.endsAt = null;
        vm.search();
    };

    vm.search = function (event) {
        if (!event || event.keyCode == 13) vm.getAll();
    };

    vm.setOrderField = function (field, direction) {
        vm.field = field;
        vm.direction = direction;
        vm.getAll();
    };

    vm.getAll = function () {
        vm.ready = false;
        var deferred = $q.defer();
        var users2 = [];

        var params = {
            count: vm.count,
            from: vm.page * vm.count,
            username: vm.username,
            direction: vm.direction,
            field: vm.field
        };
        if (vm.lastUser != vm.username || vm.lastS != vm.startsAt || vm.lastE != vm.endsAt || vm.lastC != vm.count) {
            vm.page = 0;
            params.from = 0;
        }
        vm.lastUser = vm.username;
        vm.lastS = vm.startsAt;
        vm.lastE = vm.endsAt;
        vm.lastC = vm.count;
        if (vm.startsAt)
            params.startsAt = Date.parse(vm.startsAt) / 1000;
        if (vm.endsAt)
            params.endsAt = Date.parse(vm.endsAt) / 1000;

        Blog.query(params, function (huita) {
            var blogs = huita.data;
            vm.blogs = blogs;
            vm.hasMorePages = huita.hasMorePages;

            blogs.forEach(function (user) {
                user.createdAt = new Date(user.createdAt * 1000);
                user.content = trim(user.content);
                user.title = trim(user.title);
                if (users2.indexOf(user.username) == -1)
                    users2.push(user.username);
            });

            vm.ready = true;

            params.page = vm.page;
            if (!vm.startsAt)
                params.startsAt = null;
            if (!vm.endsAt)
                params.endsAt = null;
            params.showBy = vm.count;
            $state.go('.', params, {notify: false});
            $scope.$emit('$state', params);
            vm.firstTime = false;
            deferred.resolve(users2);
        });

        deferred.promise.then(function (users2) {
            vm.users2 = [];
            if (users2.length) {
                var callback = function (users2) {
                    ok = true;
                    users2.result.forEach(function (user) {
                        if (user.rating >= 2400) {
                            user.color = 'red';
                        } else if (user.rating >= 2100) {
                            user.color = '#FF8C00';
                        } else if (user.rating >= 1900) {
                            user.color = '#AA00AA';
                        } else if (user.rating >= 1600) {
                            user.color = 'blue';
                        } else if (user.rating >= 1400) {
                            user.color = '#03A89E';
                        } else if (user.rating >= 1200) {
                            user.color = 'green';
                        } else {
                            user.color = 'gray';
                        }

                        if (!user.rating) user.color = '#000000';
                        user.lgm = user.rating >= 3000;
                        user.tourist = user.rating >= 4000;

                        vm.users2[user.handle] = user;
                    });

                    vm.blogs.forEach(function (user) {
                        if (vm.users2[user.username])
                            user.user = vm.users2[user.username];
                    });

                    vm.ready = true;
                };
                var newUsers = [];
                var ggg = function(users2, i) {
                    setTimeout(function() {
                        UserBig.query({users: users2.join(';')}).$promise.then(callback, function(x) {
                            if (users2.length == 1) return;
                            var l = users2.length + 1;
                            var users3 = users2.slice(0, l / 2);
                            var users4 = users2.slice(l / 2);
                            if (users3.length) newUsers.push(JSON.parse(JSON.stringify(users3)));
                            if (users4.length) newUsers.push(JSON.parse(JSON.stringify(users4)));
                        });
                    }, 1000 * i);
                };
                var get = function(users) {
                    newUsers = [];
                    for (var i = 0; i < users.length; ++i) ggg(users[i], i);
                    setTimeout(function() {
                        if (newUsers.length) get(newUsers);
                    }, users.length * 1000);
                };
                get([users2]);
            }
        });
    };
});
