angular.module('app', [
    'ngResource', 'ui.router'
]).config(function ($locationProvider, $httpProvider, $urlRouterProvider) {
    $locationProvider.html5Mode(true).hashPrefix('!');
    $urlRouterProvider.otherwise('/index/comments');
    //$httpProvider.defaults.withCredentials = true;
}).value('backendConfig', {
    //url: 'http://212.109.192.31:20000/api/codeforces'
    url: 'https://codeforces.igorjan94.ru/node/api/codeforces'
     //url: 'http://192.168.50.6:20000/api/codeforces'
}).directive('convertToNumber', function() {
    return {
        require: 'ngModel',
        link: function(scope, element, attrs, ngModel) {
            ngModel.$parsers.push(function(val) {
                return parseInt(val, 10);
            });
            ngModel.$formatters.push(function(val) {
                return '' + val;
            });
        }
    };
});
